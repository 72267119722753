import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import { notifyBlack } from "../actions/notifyActions";
import { renderFlowItemType } from "../helpers/typedHelpers";
import type { FlowRelation, FlowItem, FlowCase, FlowFilter, FlowItemFieldsUsed } from "../types/flowTypes";
import type { IndexType } from "../types/types";
import { getFlowCasesArray } from "../reducers/flowCases";
import { getFlowItemsArray } from "../reducers/flowItems";
import { getFlowFiltersArray } from "../reducers/flowFilters";
import tryParseJSON from "../helpers/tryParseJSON";

const myGenericReducer = makeReducerFor("FLOW_RELATION", "FlowRelationId");
import subItemReducer from "./_genericFlowSubItemReducer";
import { FieldClassification } from "../enums/FieldClassifications";
import { getFlowFiltersForSelectedFlow } from "./flowFilters";
import { getFlowCasesForSelectedFlow } from "./flowCases";
import { getOACLabelIds } from "./fieldLabels";
import { getAncestorFieldIdsForFlowItemId } from "../helpers/flowItems";
import { fieldsHaveNeedsApprovalLabels } from "../helpers/needsApproval";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowRelationsArray = createSelector(
    state => state.flowRelations.byId,
    (flowRelationsById: {| [number]: FlowRelation |}): Array < FlowRelation > => {
    const r: Array<FlowRelation> = Object.values(flowRelationsById);
    return r;
}
);

let loopLastStateSeen = null;
let loopCachedAnswers = {};

// TODO: Make a version that uses the getFlowItemChildrenIndex instead of the entire state, so we can hook it up to FlowItemPort
export const isLoopDetected = (state: Object, parentId: number, childId: number): boolean => {
    // Sanity check
    if (parentId == childId || parentId == null || childId == null) {
        return false;
    }

    // Invalidate cache if state changes
    if (state !== loopLastStateSeen) {
        loopLastStateSeen = state;
        loopCachedAnswers = {};
    }

    // Return cached answer if found
    const cacheKey = parentId + "__" + childId;
    if (cacheKey in loopCachedAnswers) {
        return loopCachedAnswers[cacheKey];
    }

    // Calculate
    const childrenIndex = getFlowItemChildrenIndex(state);
    const parentChildren = childrenIndex[parentId];
    if (parentChildren) {
        for (const child of parentChildren) {
            if (child == childId) {
                loopCachedAnswers[cacheKey] = true;
                return true;
            } else if (isLoopDetected(state, child, childId) == true) {
                loopCachedAnswers[cacheKey] = true;
                return true;
            }
        }
    }

    loopCachedAnswers[cacheKey] = false;
    return false;
};

/// Flow Relations Validations / Enforcement ///

export const cannotHaveChild = [
    "toCloud",
    "report",
    "model",
    "singleview",
    "insights",
    "dataload",
    "discoveryinsights",
    "audienceinsights",
    "cannedreport",
];
export const cannotHaveParent = ["fromCloud"];
export const childMustBeEmpty = ["split", "case"];
export const allowsMultipleParents = [
    "merge",
    "offerMerge",
    "model",
    "export",
    "script",
    "scriptdbui",
    "flowExpression",
    "multiexport",
]; // Other types can have only 1 parent

// WE completely disallow empty here, because this is for user-generated links,
// and the system generates empties.
export const allowedToBeParentOfNewRelations = (itemType: string) =>
    !cannotHaveChild.includes(itemType) && !childMustBeEmpty.includes(itemType);

export const allowedToBeChildOfNewRelations = (itemType: string, numParents: number) =>
    !cannotHaveParent.includes(itemType) && (numParents == 0 || allowsMultipleParents.includes(itemType));

export const isNewRelationValid = (
    childItemType: string,
    parentItemType: string,
    childNumParents: number,
    shouldSkipMultipleParentCheck: boolean = false
) => {
    // Some more generic checking
    if (cannotHaveChild.includes(parentItemType)) {
        const message = renderFlowItemType(childItemType) + " cannot have child items.";
        return { allowed: false, message };
    }
    if (cannotHaveParent.includes(childItemType)) {
        const message = renderFlowItemType(childItemType) + " cannot have parent items.";
        return { allowed: false, message };
    }

    if (childMustBeEmpty.includes(parentItemType) && childItemType != "empty") {
        const message = "Split and Case can only have generic items as child.";
        return { allowed: false, message };
    }

    if (!shouldSkipMultipleParentCheck && childNumParents > 0 && !allowsMultipleParents.includes(childItemType)) {
        const message = "Multiple parents are not allowed for this item.";
        return { allowed: false, message };
    }
    // Special cases
    if (parentItemType == "multiexport" && childItemType != "export") {
        // Export cannot have child items, except exports
        const message = "MultiExports cannot have child items.";
        return { allowed: false, message };
    }

    if (parentItemType == "export" && (childItemType == "script" || childItemType == "scriptdbui")) {
        return { allowed: true, message: "" };
    }

    if (
        parentItemType == "export" &&
        childItemType != "exportreport" &&
        childItemType != "toCloud" &&
        childItemType != "output" &&
        childItemType != "merge" &&
        childItemType != "script" &&
        childItemType != "scriptdbui" &&
        childItemType != "cannedreport"
    ) {
        // Export cannot have child items, except exportReport
        const message = "Exports cannot have child items.";
        return { allowed: false, message };
    }

    if (parentItemType == "flowExpression" && childItemType != "script" && childItemType != "scriptdbui") {
        const message = "Expression items may only have Script items as children.";
        return { allowed: false, message };
    }

    if (parentItemType == "output" && childItemType == "flowExpression") {
        const message = "Expression items cannot have an Output item as parent";
        return { allowed: false, message };
    }

    if (
        parentItemType == "offload" &&
        childItemType != "script" &&
        childItemType != "scriptdbui" &&
        childItemType != "offload"
    ) {
        const message = "Offload items may only have Script or Offload items as children.";
        return { allowed: false, message };
    }

    if (
        childItemType == "output" &&
        parentItemType != "export" &&
        (parentItemType != "script" || parentItemType != "scriptdbui")
    ) {
        const message = "Output items can only be children of Export, Expression or Script items.";
        return { allowed: false, message };
    }

    if (parentItemType == "singleview") {
        const message = "SingleView cannot have child items.";
        return { allowed: false, message };
    }

    if (childItemType == "exportreport" && parentItemType != "export") {
        // Exportreport can only be child of export
        const message = "Export reports can only be children of an Export item.";
        return { allowed: false, message };
    }

    if (childItemType == "export" && parentItemType == "script") {
        // Exportreport can only be child of export
        const message = "Script items cannot be a parent to an Export item.";
        return { allowed: false, message };
    }

    if (
        parentItemType == "offerMerge" &&
        !["report", "multiexport", "export", "svDedupe", "toCloud"].includes(childItemType)
    ) {
        // Offer can only have report/export/dedupe/toCloud as children
        const message = "Offers may only have types Report, Export, Dedupe, and To Cloud as children.";
        return { allowed: false, message };
    }

    if (childItemType == "insights" && parentItemType != "filter") {
        // Insights can only be child of filter
        const message = "Insights can only be children of Filter items.";
        return { allowed: false, message };
    }

    if (childItemType == "dataload" && parentItemType != "script" && parentItemType != "scriptdbui") {
        // dataload can only be a child of script
        const message = "Data Load can only be children of a Script item.";
        return { allowed: false, message };
    }

    if (
        (childItemType == "script" || childItemType == "scriptdbui") &&
        parentItemType != "script" &&
        parentItemType != "scriptdbui" &&
        parentItemType != "offload" &&
        parentItemType != "flowExpression"
    ) {
        // script can only be a child of script, scriptdbui, offload or a flowExpression
        const message = "Script can only be children of a Script, Script DB-UI, Expression or Offload item.";
        return { allowed: false, message };
    }

    if (
        childItemType == "cannedreport" &&
        parentItemType != "export" &&
        parentItemType != "multiexport" &&
        parentItemType != "script" &&
        parentItemType != "scriptdbui"
    ) {
        // Canned Report can only be a child of script, or an Export
        const message = "Canned Report can only be children of a Script or Export item.";
        return { allowed: false, message };
    }

    const discoveryParentsNotAllowed = ["script", "scriptdbui", "multiexport", "export", "output", "exportreport"];
    if (childItemType == "discovery" && discoveryParentsNotAllowed.includes(parentItemType)) {
        const message = "Audience Insights has a restricted list of parents.";
        return { allowed: false, message };
    }

    return { allowed: true, message: "" };
};

export const get3rdPartyLinked = (state: any, flowItemId: number): boolean => {
    const ancestorFields = getAncestorsFields(state);
    const flowRelations = getFlowRelationsForSelectedFlow(state);

    if (ancestorFields && flowRelations) {
        const parents: Array<number> = [];
        getAncestorsFlowItemIds(flowRelations, flowItemId, parents);
        let parentAncestorFields = ancestorFields.filter(x => parents.includes(x.FlowItemId));

        const thirdParyFields = parentAncestorFields.filter(
            x => (x.FieldClassification == FieldClassification.ThirdParty || x.FieldClassification == FieldClassification.PrivateThirdParty)
        );

        return thirdParyFields ? thirdParyFields.length > 0 : false;
    } else {
        return false;
    }
};

export const ancestorhasOACData = (state: any, flowItemId: number): boolean => {
    const flowRelations = getFlowRelationsForSelectedFlow(state);
    const flowFilters = getFlowFiltersForSelectedFlow(state);
    const flowCases = getFlowCasesForSelectedFlow(state);
    const oacLabelIds = getOACLabelIds(state);
    const enabledFieldLabels = state.fieldsByCompany.enabledFieldLabels;

    const ancestorFieldIds = getAncestorFieldIdsForFlowItemId(flowItemId, flowRelations, flowFilters, flowCases);

    return fieldsHaveNeedsApprovalLabels(ancestorFieldIds, oacLabelIds, enabledFieldLabels, false);
};

export const validateFlowRelation = (
    state: any,
    parentId: number,
    parentTypeName: string,
    childId: number,
    childTypeName: string
): boolean => {
    if (parentId == childId || parentId == null || childId == null) {
        return false;
    }

    const relationAlreadyExists = flowRelationExists(state, parentId, childId);
    const childsNumberOfParents = existingFlowRelationsCount(state, null, childId);

    // Special Case.  The old code using react-storm needs validateFlowRelation to
    // have a `relationAlreadyExists` check on ONLY THIS ONE.  Investigate further when completly
    // removing storm-react.
    if (
        childTypeName != "merge" &&
        childTypeName != "offerMerge" &&
        childTypeName != "model" &&
        childTypeName != "multiexport" &&
        childTypeName != "export" &&
        childTypeName != "script" &&
        childTypeName != "scriptdbui" &&
        childTypeName != "flowExpression" &&
        childsNumberOfParents > 0 &&
        relationAlreadyExists == false
    ) {
        // Only Merge can have multiple parents
        top.store.dispatch(
            notifyBlack("Multiple parents are allowed for Merge, Model, Offer, expressions, and Script types only.")
        );
        return false;
    }

    const allowedObj = isNewRelationValid(childTypeName, parentTypeName, childsNumberOfParents, true);
    if (!allowedObj.allowed) {
        top.store.dispatch(notifyBlack(allowedObj.message));
        return false;
    }
    return true;
};

export const flowRelationExists = (state: any, parentId: ?number, childId: ?number): boolean => {
    const flowRelations = getFlowRelationsForAllFlows(state);

    if (parentId != null && childId != null) {
        const existingRelations = flowRelations.filter(
            x => x.ParentFlowItemId == parentId && x.ChildFlowItemId == childId
        );
        return existingRelations.length > 0;
    } else if (childId != null) {
        const existingRelations = flowRelations.filter(x => x.ChildFlowItemId == childId);
        return existingRelations.length > 0;
    }

    return false;
};

export const getExistingRelations = (
    flowRelations: Array<FlowRelation>,
    parentId: ?number,
    childId: ?number
): Array<FlowRelation> => {
    if (flowRelations != null)
        if (parentId != null && childId != null) {
            return flowRelations.filter(x => x.ParentFlowItemId == parentId && x.ChildFlowItemId == childId);
        } else if (childId != null) {
            return flowRelations.filter(x => x.ChildFlowItemId == childId && x.ParentFlowItemId != 0);
        } else if (parentId != null) {
            return flowRelations.filter(x => x.ParentFlowItemId == parentId);
        }

    return [];
};

export const existingFlowRelations = (state: any, parentId: ?number, childId: ?number): Array<FlowRelation> => {
    const flowRelations = getFlowRelationsForAllFlows(state);
    return getExistingRelations(flowRelations, parentId, childId);
};

export const existingFlowRelationsCount = (state: any, parentId: ?number, childId: ?number): number => {
    const flowRelationsArray = existingFlowRelations(state, parentId, childId);
    return flowRelationsArray.length;
};

export const getFlowRelationsForAllFlows = getFlowRelationsArray;
export const getFlowRelationsForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowRelationsArray(state),
    (flowItems: Array<FlowItem>, flowRelations: Array<FlowRelation>): Array<FlowRelation> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        return flowRelations.filter(
            fr => itemIds.includes(fr.ChildFlowItemId) || itemIds.includes(fr.ParentFlowItemId)
        );
    }
);

// This shouldn't be deep equals, rather anything depending on it might want deep equals in its shouldcomponentupdate..
export const getFlowRelationIdsForSelectedFlow = createSelector(
    state => getFlowRelationsForSelectedFlow(state),
    (flowRelations: Array<FlowRelation>) => flowRelations.map(x => x.FlowRelationId)
);

export const getFlowItemChildrenIndex = createSelector(
    state => getFlowRelationsArray(state),
    (flowRelations: Array<FlowRelation>): IndexType =>
        flowRelations.reduce((acc, row) => {
            if (acc[row.ParentFlowItemId] == null) {
                acc[row.ParentFlowItemId] = [];
            }
            acc[row.ParentFlowItemId].push(row.ChildFlowItemId);
            return acc;
        }, {})
);

export const getFlowItemChildrenIndexForSelectedFlowItem = createSelector(
    state => state.selected.flowItem,
    state => getFlowItemChildrenIndex(state),
    (selectedFlowItem: number, flowItemChildrenIndex: IndexType) => flowItemChildrenIndex[selectedFlowItem] || []
);

export const getFlowItemParentIndex = createSelector(
    state => getFlowRelationsArray(state),
    (flowRelations: Array<FlowRelation>): IndexType =>
        flowRelations.reduce((acc, row) => {
            if (acc[row.ChildFlowItemId] == null) {
                acc[row.ChildFlowItemId] = [];
            }
            acc[row.ChildFlowItemId].push(row.ParentFlowItemId);
            return acc;
        }, {})
);

export const getAncestorsFields = createSelector(
    state => getFlowRelationsForSelectedFlow(state),
    state => state.filters,
    state => getFlowItemsArray(state),
    state => getFlowFiltersArray(state),
    state => getFlowCasesArray(state),
    state => state.selected.flow,
    state => state.fields.byId,
    (
        flowRelations: Array<FlowRelation>,
        filters,
        flowItems: Array<FlowItem>,
        filterArray: Array<FlowFilter>,
        caseArray: Array<FlowCase>,
        flowId,
        fieldsById: Array<Object>
    ): Array<FlowItemFieldsUsed> => {
        let usedFields = [];
        let flowItemIds = [];
        const relevantFlowItemTypes = ["export", "multiexport", "singleview", "discovery", "insights"];

        flowItems
            .filter(
                flowItem =>
                    flowItem.FlowId == flowId &&
                    relevantFlowItemTypes.includes(flowItem.FlowItemType) &&
                    flowItem.IsActive == true
            )
            .map(x => {
                flowItemIds.push(x.FlowItemId);
            });
        let ancestorIds = [];
        if (fieldsById) {
            flowItemIds.forEach(flowItemId => {
                getAncestorsFlowItemIds(flowRelations, flowItemId, ancestorIds);
                const ancestorsFilter = filterArray.filter(
                    x =>
                        ancestorIds.includes(x.FlowItemId) && x.FlowFilterCriteria != "" && x.FlowFilterCriteria != null
                );
                const ancestorsCase = caseArray.filter(
                    x => ancestorIds.includes(x.FlowItemId) && x.FlowCaseCriteria != "" && x.FlowCaseCriteria != null
                );

                for (const filter of ancestorsFilter) {
                    const includeObject = tryParseJSON(filter.FlowFilterCriteria) || {};
                    const flowItem = flowItems.find(x => x.FlowItemId == filter.FlowItemId);
                    pushUsedFields(includeObject, filters, flowItem, usedFields, fieldsById);
                }

                for (const flowItemCase of ancestorsCase) {
                    const includeObject = tryParseJSON(flowItemCase.FlowCaseCriteria) || {};
                    const flowItem = flowItems.find(x => x.FlowItemId == flowItemCase.FlowItemId);
                    pushUsedFields(includeObject, filters, flowItem, usedFields, fieldsById);
                }
            });
        }

        return usedFields;
    }
);

export function getAncestorsFlowItemIds(flowRelations, flowItemId, results) {
    const parentFlowItemIds = flowRelations
        // adding a little check to prevent running multiple times for the same parent, happens on big flows and affects performance
        .filter(
            x => x.ParentFlowItemId != 0 && x.ChildFlowItemId == flowItemId && !results.includes(x.ParentFlowItemId)
        )
        .map(x => x.ParentFlowItemId);

    for (const parentId of parentFlowItemIds) {
        results.push(parentId);
        getAncestorsFlowItemIds(flowRelations, parentId, results);
    }
}

function pushUsedFields(includeObject, filters, flowItem, usedFields, fieldsById) {
    for (const field of includeObject.rules) {
        const filterField = filters.find(x => x.id == field.id);
        const fieldName = filterField ? filterField.label : "Unknown";

        usedFields.push({
            FlowItemId: flowItem ? flowItem.FlowItemId : 0,
            FlowItemType: flowItem ? flowItem.FlowItemType : "Unknown",
            FlowItemName: flowItem ? flowItem.FlowItemName : "Unknown",
            FieldId: field.id,
            FieldName: fieldName,
            FieldTableKey: fieldsById[field.id]?.fieldTableKey,
            IsThirdParty: fieldsById[field.id]?.IsThirdParty,
            FieldClassification: fieldsById[field.id]?.FieldClassification,
        });
    }
}
