import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../actions/actionCreators";
import * as adminCompaniesActions from "../../actions/adminCompaniesActions";

import { IAppState } from "../../types/stores/index";
//import { useSaasHeader } from "@ascend/ui-core/hooks";

const SaasHeader = () => {
    const SAAS_ASSETS_URL = "https://da-saas-blade-dev-saas-assets-ui.hb-e1-dev.experianone.io";
    const enabledFeatures = useSelector((state: IAppState) => state.session.enabledFeatures || []);
    const menuopt = enabledFeatures.includes("integrate-wayfarer-header");
    // const title = "Audience Engine";
    // const HEADER_ELEMENT_KEY = "saas-header";
    // const { initialiseSaasHeader, hasError } = useSaasHeader({
    //     title,
    //     selector: HEADER_ELEMENT_KEY,
    // });

    useEffect(() => {
        if (menuopt) {
            // Insert the necessary scripts
            const script = document.createElement("script");
            script.setAttribute("type", "text/javascript");
            script.setAttribute("src", `${SAAS_ASSETS_URL}/common-components/experian-saas-header.js`);
            document.head.appendChild(script);

            // Load the webcomponents-bundle.js script
            const webComponentsScript = document.createElement("script");
            webComponentsScript.setAttribute("src", "/Scripts/js/webcomponents-bundle.js");
            document.head.appendChild(webComponentsScript);

            // Function to initialize SaasHeader
            const initializeHeader = () => {
                const SaasHeader = window.ExperianSaaSComponent.SaasHeader;
                if (SaasHeader) {
                    const saasHeaderInstance = new SaasHeader({ title: "Audience Engine", selector: "saas-header" });
                    // Call any initialization method if needed
                    saasHeaderInstance.initialize();
                }
            };

            // Prepare the SaasHeader class to be called and subsequently initialize the header
            window.addEventListener("SaasHeaderReady", initializeHeader);

            return () => {
                // Cleanup the appended elements when the component unmounts
                document.head.removeChild(script);
                document.head.removeChild(webComponentsScript);
                window.removeEventListener("SaasHeaderReady", initializeHeader);
            };
        }
    }, [menuopt]);

    return <>{menuopt && <div id="saas-header"></div>}</>;
};

SaasHeader.propTypes = {};

const mapStateToProps = state => ({
    enabledFeatures: state.session.enabledFeatures || [],
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(Object.assign({}, actionCreators, adminCompaniesActions), dispatch);

const SaasHeaderC = connect(mapStateToProps, mapDispatchToProps)(SaasHeader);

export default SaasHeaderC;
